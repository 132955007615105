/* Hide scrollbars on IE, Edge, and Firefox */
.blue-text-box {
    -ms-overflow-style: none;   /* IE and Edge */
    scrollbar-width: none;      /* Firefox */
}

/* Hide scrollbars on Chrome, Safari, and Opera */
.blue-text-box::-webkit-scrollbar {
    display: none;
}
