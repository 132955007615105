.prospective-sponsors{
    animation: fade-in 500ms ease forwards;
}

.prospective-sponsors-content {
    animation: fade-in 500ms ease forwards;
}

.current-sponsors {
    animation: fade-in 500ms ease 300ms forwards;
}

.current-sponsors-content {
    animation: fade-in 500ms ease 300ms forwards;
}
