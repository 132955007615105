.first-container {
    animation: fade-in 500ms ease 0s forwards;
}

.second-container {
    animation: fade-in 500ms ease 300ms forwards;
}

.third-container {
    animation: fade-in 500ms ease 600ms forwards;
}

#first-path {
    animation: fade-in 500ms ease 900ms forwards;
}

#second-path {
    animation: fade-in 500ms ease 900ms forwards;
}
