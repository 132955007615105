/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* React Image Gallery */
@import "~react-image-gallery/styles/css/image-gallery.css";

/* Roboto Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Global Classes */
.app-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.w-19\/20 {
    width: 95%;
}

.h-19\/20 {
    height: 95%;
}

/* Animations */
@keyframes grow-vertical {
    from {
        height: 0;
    }

    to {
        height: 100vh; 
    }    
}

@keyframes fade-in {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}
